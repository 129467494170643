// @flow
import * as React from 'react';
import { ExternalLink } from '../Styled';

type ContentType = {
  [string]: Array<{
    key: number,
    header: string,
    text: string | Array<string | React.Element<*>>,
  }>,
};

const getFarLink = () => (
  <ExternalLink
    href="https://www.far.se/"
    target="_blank"
    id="test-id-map-link"
  >
    FAR
  </ExternalLink>
);

export const content: ContentType = {
  features: [
    {
      key: 0,
      header: 'En personlig kontakt',
      text:
        'Vi tror på personligt engagemang och kompetens hos våra medarbetare. Detta i kombination med en modern företagskultur och system är det som får våra kunder att känna att de får lite extra hos oss. Lite mer än bara redovisning helt enkelt.',
    },
    {
      key: 1,
      header: 'Redovisning på dina villkor',
      text:
        'Vi anpassar våra tjänster utefter våra kunders behov och kan därmed erbjuda allt från specifika tjänster till fullständig hantering av ett företags ekonomi och administration.',
    },
    {
      key: 2,
      header: 'Vi är auktoriserade',
      text: [
        `Som auktoriserade redovisningskonsulter har vi en gedigen ekonomisk utbildning och lång praktisk erfarenhet. Vi är anslutna till `,
        getFarLink(),
        ` då förbundets tillsyn och krav på bland annat ansvarsförsäkring, kvalitetskontroll och fortbildning gör att företagaren får en trygg och seriös samarbetspartner.`,
      ],
    },
  ],
};
