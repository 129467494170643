// @flow
import * as React from 'react';
import { Helmet } from 'react-helmet';
import Observer from 'react-intersection-observer';
import { easeCubicOut } from 'd3-ease';
import { graphql } from 'gatsby';
import { Trail, animated, config } from 'react-spring';
import Rellax from 'rellax';
import { FadeUp, FadeDown, FadeRight } from '../Animations';

import Footer from '../Footer';
import {
  ServicesPage,
  Section,
  ContentWrapper,
  ContentImgBg,
  Content,
  ServiceContent,
  RelativeParent,
  FlexChild,
  H3,
  Feature,
  From,
  Price,
  Service,
} from './styles';
import {
  MainHeader,
  MainParagraph,
  SectionHeader,
  P,
  GatsbyImage,
  GatsbyImageBreaker,
} from '../Styled';
import { content } from './config';
import { Map } from 'core-js';

type State = {
  isIE: boolean,
};

type FeatureAnimationType = {
  title: string,
  price: string,
  i: number,
  inverted?: boolean,
};

const FeatureAnimation = ({
  title,
  price,
  color,
  i,
  inverted,
}: FeatureAnimationType) => (
  <FadeDown
    physics={{
      duration: 400,
      easing: easeCubicOut,
      delay: (i + 1) * 600,
    }}
  >
    <Feature>
      <FadeRight delay={(i + 1) * 800} xFrom={-20}>
        <H3 {...(inverted && { color: '#000' })}>{title}</H3>
      </FadeRight>
      {/* <FadeRight delay={(i + 1) * 1000} xFrom={-20}>
        <P {...inverted && { color: '#000' }}>
          <From>fr.</From>
          <Price {...inverted && { color: '#5D2D49' }}>{price}</Price>
        </P>
      </FadeRight> */}
    </Feature>
  </FadeDown>
);

const ContentAnimation = ({
  title,
  text,
  colorFrom,
  colorTo,
  isIE,
  header,
  inverted,
}) => {
  const renderTitle = Array.isArray(title)
    ? title.map(part => (
        <React.Fragment>
          {part}
          <br />
        </React.Fragment>
      ))
    : title;

  return (
    <React.Fragment>
      <FadeUp delay={200}>
        {header ? (
          <MainHeader colorFrom={colorFrom} colorTo={colorTo} isIE={isIE}>
            {renderTitle}
          </MainHeader>
        ) : (
          <SectionHeader colorFrom={colorFrom} colorTo={colorTo} isIE={isIE}>
            {renderTitle}
          </SectionHeader>
        )}
      </FadeUp>

      <FadeUp delay={400}>
        {header ? (
          <MainParagraph>
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </MainParagraph>
        ) : (
          <P {...(inverted && { color: '#000' })}>{text}</P>
        )}
      </FadeUp>
    </React.Fragment>
  );
};

type Props = {
  data: Object,
};

export default class Services extends React.Component<Props, State> {
  parallaxItems = new Map();
  rellaxMap = new Map();

  colors = {
    colorFrom: 'rgb(56, 25, 44)',
    colorTo: 'rgb(96, 47, 76)',
    bgDark: '#6F4963',
    bgLight: '#CC95B7',
  };

  content = {
    opening: {
      title: ['Vi säljer tjänster', 'inte siffror'],
      text: `Mcon är en modern redovisningsbyrå som erbjuder tjänster inom <strong>bokföring, redovisning, lön och administration.</strong> Som auktoriserade konsulter erbjuder vi ett tryggt och kvalitativt samarbete med våra kunder.`,
    },
    redovisning: {
      title: 'Redovisning',
      text:
        'Bokföringen är grunden i vårt arbete, men den går att anpassa på många olika sätt beroende på vad kunden vill ha hjälp med. Hela bokföringen går givetvis att sköta digitalt, eller bara delar av den. Vi kan anpassa allt utifrån vad kunden själv efterfrågar!',
    },
    lonetjanster: {
      title: 'Lönetjänster',
      text:
        'Vi erbjuder hjälp med hela löneadministrationen, inklusive arbetsgivarintyg, kontrolluppgifter och allt annat som hör löner till.',
    },
    radgivning: {
      title: 'Rådgivning',
      text:
        'Vi har många års erfarenhet av de frågeställningar som kan uppkomma inom skatt för ägarledda bolag. Varje år gör vi en genomgång av våra aktiebolag för att räkna på och planera för löneunderlag inför ev utdelning.',
    },
    analys: {
      title: 'Analys och Rapportering',
      text:
        'Vi hjälper våra kunder med kassaflödesanalyser, balans- och resultaträkning – även på koncernnivå inför kvartalsrapportering. Övriga tjänster är resultat- och likviditetsbudget, prognoser, diverse utredningar.',
    },
  };

  features = {
    redovisning: [
      { title: 'Årsredovisning', price: '2 000kr' },
      { title: 'Bolagsdeklaration', price: '2 000kr' },
    ],
    lonetjanster: [
      { title: 'Lönekörning', price: '2 000kr' },
      { title: 'Semesterberäkning', price: '2 000kr' },
    ],
    radgivning: [
      { title: 'Koncernredovisning', price: '2 000kr' },
      { title: 'Rapportering för noterade bolag', price: '2 000kr' },
    ],
    analys: [
      { title: 'Kassaflödesanalys', price: '2 000kr' },
      { title: 'Resultat- och likviditetsbudget', price: '2 000kr' },
    ],
  };

  state = {
    isIE: false,
  };

  componentDidMount() {
    this.setState({
      isIE: /*@cc_on!@*/ false || !!document.documentMode,
    });

    const items = this.parallaxItems.entries();

    for (let [key, value] of items) {
      this.rellaxMap.set(key, new Rellax(key, value));
    }
  }

  componentWillUnmount() {
    const controllers = this.rellaxMap.entries();
    for (let [key, value] of controllers) {
      value.destroy();
      this.rellaxMap.delete(key);
    }
  }

  componentDidUpdate() {
    const items = this.parallaxItems.entries();

    for (let [key, value] of items) {
      if (!this.rellaxMap.has(key)) {
        this.rellaxMap.set(key, new Rellax(key, value));
      }
    }
  }

  handleRef = (node, options = { speed: -0.5 }) => {
    if (this.parallaxItems.has(node) || !node) return;

    let parent = node.parentNode;
    while (parent.parentNode) {
      if (parent.parentNode.tagName === 'SECTION') {
        parent = parent.parentNode;
        break;
      }
      parent = parent.parentNode;
    }

    this.parallaxItems.set(node, options);
    this.forceUpdate();
  };

  render() {
    const { colorFrom, colorTo, bgDark, bgLight } = this.colors;
    const { isIE } = this.state;
    const {
      servicesMain,
      shape1,
      shape2,
      middleImg,
      shape4,
      shape5,
    } = this.props.data;

    return (
      <ServicesPage>
        <Helmet>
          <title>Mcon | Tjänster</title>
        </Helmet>
        <Section bg="#fff">
          <GatsbyImage
            fluid={servicesMain.childImageSharp.fluid}
            alt="Tjänster huvudbild"
            position="90% top"
            backgroundColor="#5d384e"
          />
          <RelativeParent>
            <ContentWrapper id="intro">
              <RelativeParent>
                <ContentImgBg
                  ref={node =>
                    this.handleRef(node, {
                      speed: 0.5,
                    })
                  }
                  url="shape0_bg.svg"
                />
                <ContentImgBg ref={this.handleRef} url="shape0.svg" />

                <Observer triggerOnce>
                  {({ inView, ref }) => (
                    <Content ref={ref}>
                      {inView && (
                        <ContentAnimation
                          {...this.content.opening}
                          {...this.colors}
                          isIE={isIE}
                          header
                        />
                      )}
                    </Content>
                  )}
                </Observer>
              </RelativeParent>
            </ContentWrapper>
          </RelativeParent>
        </Section>

        <Section bg="none">
          <Observer triggerOnce>
            {({ inView, ref }) => (
              <Service id="s1" ref={ref}>
                {inView && (
                  <React.Fragment>
                    <FadeUp>
                      <SectionHeader
                        colorFrom={colorFrom}
                        colorTo={colorTo}
                        isIE={isIE}
                      >
                        Vi jobbar utefter dina behov
                      </SectionHeader>
                    </FadeUp>

                    <Trail
                      items={content.features}
                      keys={content.features.map(feature => feature.key)}
                      from={{
                        opacity: 0,
                        x: -50,
                      }}
                      to={{ opacity: 1, x: 0 }}
                      config={config.molasses}
                      delay={600}
                      native
                    >
                      {({ header, key, text }) => ({ opacity, x }) => (
                        <animated.div
                          style={{
                            opacity,
                            transform: x.interpolate(
                              x => `translate3d(${x}px, 0, 0)`
                            ),
                          }}
                        >
                          <Feature key={key} borderColor="#F7F2F5">
                            <H3 color="#000">{header}</H3>
                            <P color="#000" light>
                              {text}
                            </P>
                          </Feature>
                        </animated.div>
                      )}
                    </Trail>
                  </React.Fragment>
                )}
              </Service>
            )}
          </Observer>

          <FlexChild>
            <ContentWrapper id="s1">
              <RelativeParent>
                <ContentImgBg
                  url="shape1_bg.svg"
                  ref={node => this.handleRef(node, { speed: 0.5 })}
                />
                <div ref={this.handleRef} style={{ height: '100%' }}>
                  <GatsbyImage
                    fluid={shape1.childImageSharp.fluid}
                    contain
                    alt="Tjänster utefter kundes behov bild"
                  />
                </div>
              </RelativeParent>
            </ContentWrapper>
          </FlexChild>
        </Section>

        <Observer triggerOnce>
          {({ inView, ref }) => (
            <Section
              bg={`linear-gradient(to right, ${bgDark}, ${bgLight})`}
              ref={ref}
              style={{ zIndex: 1 }}
            >
              {inView && (
                <React.Fragment>
                  <FlexChild>
                    <ContentWrapper id="s2">
                      <RelativeParent>
                        <ContentImgBg
                          url="shape2_bg.svg"
                          ref={node =>
                            this.handleRef(node, {
                              speed: 0.3,
                            })
                          }
                        />
                        <div
                          ref={node =>
                            this.handleRef(node, {
                              speed: -0.3,
                            })
                          }
                          style={{ height: '100%' }}
                        >
                          <GatsbyImage
                            fluid={shape2.childImageSharp.fluid}
                            contain
                            alt="Redovisning bild"
                          />
                        </div>
                      </RelativeParent>
                    </ContentWrapper>
                  </FlexChild>
                  <Service id="s2">
                    <ContentAnimation
                      {...this.content.redovisning}
                      {...this.colors}
                      isIE={isIE}
                    />
                    {this.features.redovisning.map((props, i) => (
                      <FeatureAnimation key={props.title} {...props} i={i} />
                    ))}
                  </Service>
                </React.Fragment>
              )}
            </Section>
          )}
        </Observer>

        <Section bg="#fff">
          <div
            ref={node =>
              this.handleRef(node, {
                speed: 0.75,
              })
            }
            style={{ height: '100%', width: '100%' }}
          >
            <GatsbyImageBreaker
              fluid={middleImg.childImageSharp.fluid}
              position="center"
              alt="Lönetjänster bild"
            />
          </div>

          <Observer triggerOnce>
            {({ inView, ref }) => (
              <ContentWrapper id="s3" ref={ref}>
                <RelativeParent>
                  <ContentImgBg
                    url="shape3_bg.svg"
                    ref={node => this.handleRef(node, { speed: 0.1 })}
                  />
                  <ContentImgBg
                    url="shape3.svg"
                    ref={node => this.handleRef(node, { speed: -0.1 })}
                  />
                  {inView && (
                    <ServiceContent id="c3">
                      <ContentAnimation
                        {...this.content.lonetjanster}
                        {...this.colors}
                        isIE={isIE}
                      />
                      {this.features.lonetjanster.map((props, i) => (
                        <FeatureAnimation key={props.title} {...props} i={i} />
                      ))}
                    </ServiceContent>
                  )}
                </RelativeParent>
              </ContentWrapper>
            )}
          </Observer>
        </Section>

        <Observer triggerOnce>
          {({ inView, ref }) => (
            <Section id="section-4" ref={ref} bg="#fff">
              {inView && (
                <React.Fragment>
                  <Service id="s4">
                    <ContentAnimation
                      {...this.content.radgivning}
                      {...this.colors}
                      isIE={isIE}
                      inverted
                    />
                    {this.features.radgivning.map((props, i) => (
                      <FeatureAnimation
                        key={props.title}
                        {...props}
                        i={i}
                        inverted
                      />
                    ))}
                  </Service>
                  <FlexChild>
                    <ContentWrapper id="s4">
                      <RelativeParent>
                        <ContentImgBg
                          url="shape4_bg.svg"
                          ref={node => this.handleRef(node, { speed: 0.1 })}
                        />
                        <div
                          ref={node =>
                            this.handleRef(node, {
                              speed: -0.1,
                            })
                          }
                          style={{ height: '100%' }}
                        >
                          <GatsbyImage
                            fluid={shape4.childImageSharp.fluid}
                            contain
                            alt="Rådgivning bild"
                          />
                        </div>
                      </RelativeParent>
                    </ContentWrapper>
                  </FlexChild>
                </React.Fragment>
              )}
            </Section>
          )}
        </Observer>

        <Observer triggerOnce>
          {({ inView, ref }) => (
            <Section
              ref={ref}
              bg={`linear-gradient(to right, ${bgDark}, ${bgLight})`}
            >
              {inView && (
                <React.Fragment>
                  <FlexChild>
                    <ContentWrapper id="s5">
                      <RelativeParent>
                        <ContentImgBg
                          url="shape5_bg.svg"
                          ref={node => this.handleRef(node, { speed: 0.1 })}
                        />
                        <div
                          ref={node => this.handleRef(node, { speed: -0.1 })}
                          style={{ height: '100%' }}
                        >
                          <GatsbyImage
                            fluid={shape5.childImageSharp.fluid}
                            contain
                            alt="Analys och rapportering bild"
                          />
                        </div>
                      </RelativeParent>
                    </ContentWrapper>
                  </FlexChild>
                  <Service id="s5">
                    <ContentAnimation
                      {...this.content.analys}
                      {...this.colors}
                      isIE={isIE}
                    />
                    {this.features.analys.map((props, i) => (
                      <FeatureAnimation key={props.title} {...props} i={i} />
                    ))}
                  </Service>
                </React.Fragment>
              )}
            </Section>
          )}
        </Observer>

        <Footer
          color="#fff"
          backgroundColor={`linear-gradient(to right, ${bgDark}, ${bgLight})`}
        />
      </ServicesPage>
    );
  }
}

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 3000) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`;

export const fluidImageSmall = graphql`
  fragment fluidImageSmall on File {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    servicesMain: file(relativePath: { eq: "services/servicesMain.jpg" }) {
      ...fluidImage
    }
    shape1: file(relativePath: { eq: "services/shape1.png" }) {
      ...fluidImage
    }
    shape2: file(relativePath: { eq: "services/shape2.png" }) {
      ...fluidImage
    }
    middleImg: file(relativePath: { eq: "services/middleSection.jpg" }) {
      ...fluidImage
    }
    shape4: file(relativePath: { eq: "services/shape4.png" }) {
      ...fluidImage
    }
    shape5: file(relativePath: { eq: "services/shape5.png" }) {
      ...fluidImage
    }
  }
`;
